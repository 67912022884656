<template>
  <div class="create-or-edit">
    <div>
      <el-form ref="productInfoForm" :rules="rules" :model="productInfo" label-position="left" label-width="120px" size="small">
        <el-form-item label="指派需方：" prop="need">
          <el-select v-model="productInfo.need" clearable>
            <el-option v-for="item in needStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否置顶：" prop="top">
          <el-select v-model="productInfo.top" clearable>
            <el-option v-for="item in topStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生产类型：" prop="productType">
          <el-select v-model="productInfo.productType" clearable>
            <el-option v-for="item in productTypeList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称：" prop="product">
          <el-input v-model="productInfo.product"></el-input>
        </el-form-item>
        <el-form-item label="重量(KG)：" prop="weight">
          <el-input v-model="productInfo.weight"></el-input>
        </el-form-item>
        <el-form-item label="权重：" prop="score">
          <el-input v-model="productInfo.score"></el-input>
        </el-form-item>

        <el-form-item label="备注" >
          <el-input type="textarea" placeholder="请输入备注，不超过200字" v-model="productInfo.mark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onsubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from '@/services/user'
import {saveProductInfo} from '@/services/produce'
import {findAllProductStatus, findAllProductType, findAllNeedStatus, findAllTopStatus} from '@/services/status'


export default {
  name: 'Edit',
  props: {
    productInfo: {
      type: [Object]
    },
  },
  created () {
    this.loadUserInfo()
    this.loadProductType()
    this.loadNeedStatus()
    this.loadTopStatus()
  },
  data () {
    return {
      userInfo: {},
      productStatusList: [],
      topStatusList: [],
      needStatusList: [],
      productTypeList: [],
      // 用于设置表单校验规则
      rules: {
        need: [{required: true,trigger: 'blur',message: '指派需方不能为空'}],
        top: [{required: true,trigger: 'blur',message: '是否置顶不能为空'}],
        productType: [{required: true,trigger: 'blur',message: '生产类型不能为空'}],
        product: [{required: true,trigger: 'blur',message: '产品不能为空'}],
        weight: [{required: true,trigger: 'blur',message: '重量不能为空'}],
        score: [{required: true,trigger: 'blur',message: '权重不能为空'}]
      },
    }
  },
  methods: {
    async loadProductType() {
      const {data} = await findAllProductType()
      if (200 === data.code) {
        this.productTypeList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },
    async loadProductStatus() {
      const {data} = await findAllProductStatus()
      if (200 === data.code) {
        this.productStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },

    async loadTopStatus() {
      const {data} = await findAllTopStatus()
      if (200 === data.code) {
        this.topStatusList = data.data
      } else {
        this.$message.error('查询置顶列表失败')
      }
    },

    async loadNeedStatus() {
      const {data} = await findAllNeedStatus()
      if (200 === data.code) {
        this.needStatusList = data.data
      } else {
        this.$message.error('查询需方列表失败')
      }
    },
    onCancel () {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.productInfo = {}
    },
    async onsubmit () {
      // 设置校验
      await this.$refs.productInfoForm.validate()
      this.productInfo.tackName=this.userInfo.userName
      this.productInfo.tackPhone=this.userInfo.phone
      const { data } = await saveProductInfo(this.productInfo)
      if (200 === data.code) {
        // 关闭提示框(需要子组件向父组件传递状态)
        this.$emit('success')
        // 设置成功提示
        this.$message.success('修改成功')
      } else {
        this.$message.error(data.message)
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
</style>
